import * as React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import {
    Container,
    Grid,
    MantineTheme,
    Space,
    Text,
    Title,
    useMantineTheme,
    Stack,
    Anchor,
    MediaQuery, Flex, createStyles
} from "@mantine/core";

// illustrations
import checklistSVG from '../../images/illustrations/checklist.svg';

const useStyles = createStyles((theme:MantineTheme) => ({

        alignContent: {
            [theme.fn.smallerThan('md')]:{
                textAlign:'center',
            }
        },

        textFont: {
            [theme.fn.smallerThan('md')]:{
                fontSize: "calc(36px + 1vw)",
                textAlign:'center',
                paddingLeft:theme.spacing.xl,
                paddingRight:theme.spacing.xl,
                lineHeight: 1.5
            }
        },

        qminoMailTag:{
            [theme.fn.smallerThan('md')]: {
                fontSize: "calc(32px + 1vw)",
            }
        }
}))

const DisclaimerPage = () => {
    const { classes } = useStyles()
    const theme: MantineTheme = useMantineTheme();

    const InfoMailto = () =>
        <Anchor
            className={classes.qminoMailTag}
            href="mailto:hello@qmino.com"
            target='_blank'
            color='brand.7'>
            <strong>hello@qmino.com</strong>
        </Anchor>
    return (
        <Layout>
            <Container py="5rem" size="lg">
                <Grid>
                    <Grid.Col offset={3} offsetMd={0} offsetSm={2} span={6} xs={12} sm={8}>
                        <Title order={1} my="xl">
                            <Text
                                size={40}
                                color="#444444"
                                sx={{
                                    lineHeight: 1.3,
                                    [theme.fn.smallerThan('md')]:{
                                        textAlign:'center',
                                        fontSize: "calc(36px + 1vw)",
                                    }
                                }}>
                                Website disclaimer
                            </Text>
                        </Title>
                        <Space h="xl"/>
                        <div>
                            <Text w='auto' size={20} color="gray.7" mt="xl" className={classes.textFont}>
                                Door de toegang tot en het gebruik van de website verklaart u zich
                                uitdrukkelijk akkoord met de volgende algemene voorwaarden.
                            </Text>
                        </div>
                    </Grid.Col>
                    <MediaQuery smallerThan={'sm'} styles={{display: 'none'}}>
                        <Grid.Col offset={3} offsetMd={0} offsetSm={4} sm={4}>
                            <img
                                src={checklistSVG}
                                alt={'disclaimer checklist'}
                                height={260}
                                style={{
                                    position: 'relative',
                                    top: '120px',
                                    left: '50px'
                                }}
                            />
                        </Grid.Col>
                    </MediaQuery>
                </Grid>
            </Container>
            <div style={{backgroundColor: theme.colors.gray[1]}}>
                <Container py="5rem" size="lg">
                    <Grid>
                        <Grid.Col offset={0} offsetMd={0} offsetSm={0} xs={12} lg={10}>
                            <Stack spacing={"xl"} className={classes.alignContent}>
                                <Stack spacing={"sm"}>
                                    <Title className={classes.textFont} order={2}>Intellectuele eigendomsrechten</Title>
                                    <Text className={classes.textFont}>
                                        De inhoud van deze site, met inbegrip van de merken, logo’s, tekeningen, data,
                                        product- of bedrijfsnamen, teksten, beelden e.d. zijn beschermd door intellectuele rechten en
                                        behoren toe aan Qmino BV of rechthoudende derden.
                                    </Text>
                                </Stack>
                                <Stack spacing={"sm"}>
                                    <Title className={classes.textFont} order={2}>Beperking van aansprakelijkheid</Title>
                                    <Text className={classes.textFont}>
                                        De informatie op de website is van algemene aard. De informatie is niet
                                        aangepast aan persoonlijke of specifieke omstandigheden, en kan dus niet als een persoonlijk,
                                        professioneel of juridisch advies aan de gebruiker worden beschouwd.
                                    </Text>
                                    <Text className={classes.textFont}>
                                        Qmino levert grote inspanningen opdat de ter beschikking gestelde informatie
                                        volledig, juist, nauwkeurig en bijgewerkt zou zijn. Ondanks deze inspanningen kunnen onjuistheden
                                        zich voordoen in de ter beschikking gestelde informatie. Indien de verstrekte informatie
                                        onjuistheden zou bevatten of indien bepaalde informatie op of via de site onbeschikbaar zou zijn, zal
                                        Qmino de grootst mogelijke inspanning leveren om dit zo snel mogelijk recht te zetten.
                                        Qmino kan evenwel niet aansprakelijk worden gesteld voor rechtstreekse of onrechtstreekse schade
                                        die onstaat uit het gebruik van de informatie op deze site. Indien je onjuistheden
                                        zou vaststellen in de informatie die via de site ter beschikking wordt gesteld, kan je mailen
                                        naar <InfoMailto/>.
                                    </Text>
                                    <Text className={classes.textFont}>
                                        De inhoud van de site (links inbegrepen) kan te allen tijde zonder aankondiging
                                        of kennisgeving aangepast, gewijzigd of aangevuld worden. Qmino geeft geen garanties voor
                                        de goede werking van de website en kan op geen enkele wijze aansprakelijk gehouden worden
                                        voor een slechte werking of tijdelijke (on)beschikbaarheid van de website of voor enige vorm van
                                        schade, rechtstreekse of onrechtstreekse, die zou voortvloeien uit de toegang tot of het
                                        gebruik van de website.
                                    </Text>
                                    <Text className={classes.textFont}>
                                        Qmino kan in geen geval tegenover wie dan ook, op directe of indirecte,
                                        bijzondere of andere wijze aansprakelijk worden gesteld voor schade te wijten aan het gebruik
                                        van deze site of van een andere, inzonderheid als gevolg van links of hyperlinks, met
                                        inbegrip, zonder beperking, van alle verliezen, werkonderbrekingen, beschadiging van programma's
                                        of andere gegevens op het computersysteem, van apparatuur, programmatuur of andere van de gebruiker.
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Title className={classes.textFont} order={2}>Hyperlinks en verwijzingen</Title>
                                    <Text className={classes.textFont}>
                                        De website kan hyperlinks bevatten naar websites of pagina's van derden, of daar
                                        onrechtstreeks naar verwijzen. Het plaatsen van links naar deze websites of pagina’s impliceert
                                        op geen enkele wijze een impliciete goedkeuring van de inhoud ervan. Qmino verklaart uitdrukkelijk dat
                                        zij geen zeggenschap heeft over de inhoud of over andere kenmerken van deze websites en
                                        kan in geen geval aansprakelijk gehouden worden voor de inhoud of de kenmerken ervan of voor enige
                                        andere vorm van schade door het gebruik ervan.
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Title className={classes.textFont} order={2}>Toepasselijk recht en bevoegde rechtbanken</Title>
                                    <Text className={classes.textFont}>
                                        Het Belgisch recht is van toepassing op deze site. In geval van een geschil zijn
                                        enkel de rechtbanken van het arrondissement Leuven bevoegd.
                                    </Text>
                                </Stack>
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </Container>
            </div>
        </Layout>
    );
}

export const Head = () => (
    <SEO title='Disclaimer'/>
);

export default DisclaimerPage;
